import "react-toastify/dist/ReactToastify.css";

import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import { FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, TextField, responsiveFontSizes } from '@mui/material';
import React, { useEffect, useState } from "react";

import AddIcon from '@mui/icons-material/Add';
import Buttonn from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import axios from "axios";
import myUserStore from "../stores/UserStore";
import { observer } from "mobx-react-lite"
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const ManagerForm = () => {
  const history = useHistory();
  const [competitions, setCompetitions] = useState([]);
  const [race, setRace] = useState("");
  const [temperature, setTemperature] = useState(25);
  const [sessionNumber, setSessionNumber] = useState("1");
  const [trackCondition, setTrackCondition] = useState("");
  const [sessionType, setSessionType] = useState("");
  const [tiresType, setTiresType] = useState("");
  const [rain, setRain] = useState("0");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [category, setCategory] = useState("");
  const [insertCarb, setInsertCarb] = useState(false);
  const [teamTires, setTeamTires] = useState([]);

  useEffect(() => {
    console.log("Competitions:", competitions);
    console.log("Race:", race);
    console.log("Temperature:", temperature);
    console.log("Category:", category);
    console.log("Session Number:", sessionNumber);
    console.log("Track Condition:", trackCondition);
    console.log("Session Type:", sessionType);
    console.log("Tires Type:", tiresType);
    console.log("Rain:", rain);
    console.log("Loading:", loading);
    console.log("User Data:", userData);
  }, [
    competitions,
    race,
    temperature,
    sessionNumber,
    trackCondition,
    sessionType,
    tiresType,
    rain,
    loading,
    userData,
  ]);

  const parsedUserData = JSON.parse(localStorage.getItem("user_data"))
  const selectedTeam = myUserStore.getSelectedTeam;
  const performQuery = (e) => {
    setLoading(true)
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/insert_manager_data/?email=${parsedUserData.email}&race=${race}&category=${category}&rain=${rain}&session_number=${sessionNumber}&session_type=${sessionType}&team_id=${selectedTeam.id}&temperature=${temperature}&track_condition=${trackCondition}&tires_type=${tiresType}`;

    console.log("API URL:", apiUrl);
    setLoading(true);

    axios
      .post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
      .then((response) => {
        setLoading(false)
        const data = response.data;
        console.log("Response Data:", data);
        toast.success("Data inserted correctly!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
      })
      .catch((error) => {
        setLoading(false)
        toast.error("Data not inserted correctly!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  useEffect(() => {
    console.log("Fetching competitions...");
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_competitions/?team_id=${selectedTeam.id}`,
        {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`,
						accept: "application/json",
					},
				}
      )
      .then((res) => {
        const competitions = res.data;
        console.log("Competitions data received:", competitions);
        setCompetitions(competitions);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, [, selectedTeam]);

  const handleInputClick = (event) => {
    setInsertCarb(!insertCarb)
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_team_tires`, {
        params: {
          team_id: selectedTeam.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        setTeamTires(res.data)
        console.log("team carburetors", res.data)
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, [, selectedTeam]);

  return (
    <Container>
      <style>
        {`
          .form-group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 15px;
          }

          .form-group label {
            margin-bottom: 0; /* No margin below label by default */
          }

          @media (max-width: 1150px) {
            .form-group {
              height: 120px; /* Height only for viewports below 1150px */
            }
            .form-group label {
              margin-bottom: 8px; /* Add margin below label for smaller viewports */
            }
          }

          .form-control {
            flex-grow: 1;
          }

          @media (max-width: 768px) {
            .form-group {
              height: auto;
              margin-bottom: 20px;
            }
          }
        `}
      </style>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          performQuery(e);
        }}
      >
        <Row>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="form-group">
              <Typography>Select race:</Typography>
              <FormControl style={{ width: '100%' }}>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  value={race}
                  placeholder="Select Race"
                  onChange={(e) => setRace(e.target.value)}
                  required
                >
                  {competitions.length === 0
                    ? <MenuItem value="">Please Insert a Competition</MenuItem>
                    : [
                      <MenuItem key="select-race" value="">Select Race</MenuItem>,
                      ...competitions.map((competition) => (
                        <MenuItem key={competition.id} value={competition.id}>
                          {competition.competition} ({competition.track})
                        </MenuItem>
                      ))
                    ]
                  }
                </Select>
              </FormControl>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="form-group">
              <Typography>Select Category:</Typography>
              <FormControl style={{ width: '100%' }}>
                <Select
                  name="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                >
                  <MenuItem value="">Category</MenuItem>
                  <MenuItem value="MINI">Mini</MenuItem>
                  <MenuItem value="JUNIOR">Junior</MenuItem>
                  <MenuItem value="SENIOR">Senior</MenuItem>
                  <MenuItem value="SHIFTER">Shifter</MenuItem>
                </Select>
              </FormControl>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="form-group">
              <Typography>Select Type of Session:</Typography>
              <FormControl style={{ width: '100%' }}>
                <Select
                  name="sessionType"
                  value={sessionType}
                  onChange={(e) => setSessionType(e.target.value)}
                  required
                >
                  <MenuItem value="">Select Type</MenuItem>
                  <MenuItem value="Practice">Practice</MenuItem>
                  <MenuItem value="Qualification">Qualification</MenuItem>
                  <MenuItem value="Race">Race</MenuItem>
                </Select>
              </FormControl>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="form-group">
              <Typography>Select the Session Number:</Typography>
              <FormControl style={{ width: '100%' }}>
                <Select
                  name="sessionNumber"
                  value={sessionNumber}
                  onChange={(e) => setSessionNumber(e.target.value)}
                  required
                >
                  <MenuItem value="">Session Number</MenuItem>
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                </Select>
              </FormControl>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            {!insertCarb ? (
              <Form.Group className="form-group">
                <Typography>Tire type:</Typography>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    name="tiresType"
                    value={tiresType}
                    onChange={(e) => setTiresType(e.target.value)}
                    required
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: 'auto',
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {teamTires.map((carb, index) => (
                      <MenuItem key={index} value={carb.tires_type}>{carb.tires_type}</MenuItem>
                    ))}
                    <MenuItem sx={{ justifyContent: 'center' }}>
                      <Chip color='info' size='small' sx={{ width: '70%' }} onClick={handleInputClick} label="add" icon={<AddIcon />} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Form.Group>
            ) : (
              <>
                <Typography>Tire type:</Typography>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <OutlinedInput id="tiresType" value={tiresType} onChange={(e) => setTiresType(e.target.value)} />
                </FormControl>
              </>
            )}
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="form-group">
              <Typography>Asphalt Temperature(Celsius):</Typography>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <OutlinedInput
                  type="number"
                  name="temperature"
                  value={temperature}
                  onChange={(e) => setTemperature(e.target.value)}
                  min="-15"
                  max="50"
                  required
                />
              </FormControl>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="form-group" controlId="rainSelect">
              <Typography>Does it Rain?</Typography>
              <FormControl style={{ width: '100%' }}>
                <Select
                  name="rain"
                  value={rain}
                  onChange={(e) => setRain(e.target.value)}
                  required
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                  <MenuItem value="3">A lot</MenuItem>
                  <MenuItem value="2">A little </MenuItem>
                </Select>
              </FormControl>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={4}>
            <Form.Group className="form-group" controlId="trackCondition">
              <Typography>What was the condition of the track?</Typography>
              <FormControl style={{ width: '100%' }}>
                <Select
                  name="trackCondition"
                  value={trackCondition}
                  onChange={(e) => setTrackCondition(e.target.value)}
                  required
                >
                  <MenuItem value="">Select Track Condition</MenuItem>
                  <MenuItem value="0">Dry</MenuItem>
                  <MenuItem value="1">Dump</MenuItem>
                  <MenuItem value="2">Wet</MenuItem>
                </Select>
              </FormControl>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6} md={4}>
            <Form.Group  controlId="kartSlipSelect">
              <Buttonn variant='contained' disabled={loading} onClick={performQuery}>
                {loading ? "Submitting..." : "Submit"}
              </Buttonn>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default observer(ManagerForm);
